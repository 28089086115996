import React from "react";
import "./ShopSection.scss";

function ShopSection() {
  return (
    <div className="qna-section-container my-story-section">
      <div className="qna-wrapper  my-story-section">
        <div className="section-title">
          <p className="section-title-text">Available to Buy</p>
          <span className="section-title-style"></span>
        </div>
        <div className="items-grid-container">
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image0.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image1.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image2.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image3.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image5.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image6.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image7.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image8.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image9.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image10.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/image11.jpeg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/2b6rQzp8.jpg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/OQQYlyfL.jpg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/v_Dfu1ay.jpg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/7kUEYZ_R.jpg" />
            </div>
          </div>

          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/kVr1ScLp.jpg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/x6pyC0Lo.jpg" />
            </div>
          </div>
          <div className="item-container">
            <div className="item-img-container">
              <img className="item-img" src="./assets/shop/ZOI2MLPT.jpg" />
            </div>
          </div>
        </div>

        <p className="buy-guidance-text">
          <span className="highlight">Note:</span> All these items are my worn
          items. If you are interested in buying these items please contact me.
          Please use contact links mentioned in contact section.
        </p>
      </div>
    </div>
  );
}

export default ShopSection;
