import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";

import React from "react";
import ContactSocialLink from "./ContactSocialLink";
import "./SocialMedia.scss";

function SocialMedia() {
  return (
    <div className="social-media-section-container">
      <div className="social-media-wrapper">
        <div className="section-title">
          <p className="section-title-text">Contact</p>
          <span className="section-title-style"></span>
        </div>

        <div className="devider">
          <div className="contact-me-img">
            <img src="./assets/contact-bg.jpg" />
          </div>

          <div>
            <div>
              <ContactSocialLink
                isLinkBtn={true}
                icon={faInstagram}
                name="katherinefeetlondon"
                link="https://www.instagram.com/katherinelondonfeet/"
                iconColor={"#ED4956"}
              />
              <ContactSocialLink
                isLinkBtn={true}
                icon={faInstagram}
                name="katherinelondon_fanpage"
                link="https://www.instagram.com/katherinelondon_fanpage/"
                iconColor={"#972397"}
              />
              <ContactSocialLink
                isLinkBtn={true}
                icon={faTwitter}
                name="worldsexiestkt"
                link="https://twitter.com/worldsexiestkt"
                iconColor={"#1D9BF0"}
              />
              <ContactSocialLink
                isLinkBtn={false}
                icon={faMailBulk}
                name="worldsexiestfeet54@gmail.com"
                iconColor={"blue"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
