import React, { useState, useEffect, useRef } from "react";
import "./Nav.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDollar,
  faCameraRetro,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { gsap } from "gsap";

import { Link } from "react-router-dom";

const Nav = () => {
  const [burger, setBurger] = useState(false);
  const ref_slide_link_1 = useRef();
  const ref_slide_link_2 = useRef();
  const ref_slide_link_3 = useRef();
  const ref_slide_link_4 = useRef();
  const ref_slide_link_5 = useRef();
  const ref_slide_link_6 = useRef();

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 768) setBurger(false);
    });
  }, []);

  useEffect(() => {
    if (burger) {
      document.body.style.overflow = "hidden";
      gsap.from(ref_slide_link_1.current, {
        opacity: 0,
        x: -30,
        duration: 0.4,
      });
      gsap.from(ref_slide_link_2.current, { opacity: 0, x: 30, duration: 0.4 });
      gsap.from(ref_slide_link_3.current, {
        opacity: 0,
        y: 50,
        duration: 0.4,
        delay: 0.3,
      });
      gsap.from(ref_slide_link_4.current, {
        opacity: 0,
        y: 50,
        duration: 0.4,
        delay: 0.4,
      });
      gsap.from(ref_slide_link_5.current, {
        opacity: 0,
        y: 50,
        duration: 0.4,
        delay: 0.5,
      });
      gsap.from(ref_slide_link_6.current, {
        opacity: 0,
        y: 50,
        duration: 0.4,
        delay: 0.5,
      });
    } else {
      document.body.style.overflow = "";
    }
  }, [burger]);

  const handleBurger = () => {
    setBurger(!burger);
    console.log(burger);
  };

  return (
    <div className="nav-container">
      {/* logo */}
      <div className="logo-container">
        <Link to="/">
          <img className="logo-img" src="./assets/logo.png" />
        </Link>
        <Link to="/" className="logo-txt-link">
          <p className="logo-text">
            world<span className="logo-text-span">Sexiest</span>Feet
          </p>
        </Link>
      </div>

      {/* nav links */}
      <div className="nav-links-container">
        <div className="nav-links-left-sep">
          {/* OnlyFans */}
          <a
            href="https://onlyfans.com/worldsexiestfeet"
            target="_blank"
            className="nav-link"
          >
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={faCommentDollar} />
            </span>
            <span>OnlyFans</span>
          </a>
          {/* Gallery*/}
          <Link to="/gallery" className="nav-link">
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={faCameraRetro} />
            </span>
            <span>Gallery</span>
          </Link>
        </div>
        <span className="nav-links-seperator">|</span>
        <div className="nav-links-right-sep">
          {/* twitter */}
          <a
            className="nav-social-media-link"
            href="https://twitter.com/worldsexiestkt"
            target="_blank"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          {/* Instagram */}
          <a
            className="nav-social-media-link"
            href="https://www.instagram.com/katherinelondonfeet/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          {/* Instagram */}
          <a
            className="nav-social-media-link"
            href="https://www.instagram.com/katherinelondon_fanpage/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>

      {/* burger-menu */}
      <div className="burger-menu-container">
        <div
          className={`burger-menu ${burger ? "open" : ""} `}
          onClick={handleBurger}
        >
          <div className="burger-slice"></div>
          <div className="burger-slice"></div>
          <div className="burger-slice"></div>
        </div>
      </div>

      {burger && (
        <div className={`slide-menu ${burger ? "open" : ""}`}>
          <div className="slide-menu-main-links-container">
            {/* OnlyFans */}
            <a
              href="https://onlyfans.com/worldsexiestfeet"
              className="nav-link"
              ref={ref_slide_link_1}
            >
              <span className="nav-link-icon">
                <FontAwesomeIcon icon={faCommentDollar} />
              </span>
              <span>OnlyFans</span>
            </a>

            <Link
              to="/gallery"
              className="nav-link"
              ref={ref_slide_link_2}
              onClick={handleBurger}
            >
              <span className="nav-link-icon">
                <FontAwesomeIcon icon={faCameraRetro} />
              </span>
              <span>Gallery</span>
            </Link>

            <div className="slide-menu-seperator" ref={ref_slide_link_3}></div>
            <div className="slide-menu-social-media-links-container">
              {/* twitter */}
              <a
                className="nav-social-media-link"
                href="https://twitter.com/worldsexiestkt"
                ref={ref_slide_link_4}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              {/* Instagram */}
              <a
                className="nav-social-media-link"
                href="https://www.instagram.com/katherinelondonfeet/"
                ref={ref_slide_link_5}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>

              {/* Instagram */}
              <a
                className="nav-social-media-link"
                href="https://www.instagram.com/katherinelondon_fanpage/"
                ref={ref_slide_link_6}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Nav;
