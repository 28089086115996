import React from "react";
import "./Gallery.scss";
import HeelsSection from "./HeelsSection/HeelsSection";

function Gallery() {
  return (
    <div className="gallery-wrapper">
      <HeelsSection
        sectionTitle={"High Heels"}
        images={[
          "./assets/gallery/heels/Untitled design (3).png",
          "./assets/gallery/heels/Untitled design (4).png",
          "./assets/gallery/heels/Untitled design (9).png",
          "./assets/gallery/heels/Untitled design (10).png",
          "./assets/gallery/heels/Untitled design.png",
          "./assets/gallery/heels/Untitled design (11).png",
          "./assets/gallery/heels/Untitled design (1).png",
          "./assets/gallery/heels/Untitled design (2).png",
          "./assets/gallery/heels/Untitled design (5).png",
          "./assets/gallery/heels/Untitled design (6).png",
          "./assets/gallery/heels/Untitled design (7).png",
          "./assets/gallery/heels/Untitled design (8).png",
          "./assets/gallery/heels/Untitled design (12).png",
          "./assets/gallery/heels/Untitled design (13).png",
          "./assets/gallery/heels/Untitled design (15).png",
        ]}
      />
      <HeelsSection
        sectionTitle={"Sandals"}
        images={[
          "./assets/gallery/sandals/Untitled design (13).png",
          "./assets/gallery/sandals/Untitled design (14).png",
          "./assets/gallery/sandals/Untitled design (1).png",
          "./assets/gallery/sandals/Untitled design (2).png",
          "./assets/gallery/sandals/Untitled design (3).png",
          "./assets/gallery/sandals/Untitled design (4).png",
          "./assets/gallery/sandals/Untitled design (9).png",
          "./assets/gallery/sandals/Untitled design (10).png",
          "./assets/gallery/sandals/Untitled design (5).png",
          "./assets/gallery/sandals/Untitled design (6).png",
          "./assets/gallery/sandals/Untitled design (7).png",
          "./assets/gallery/sandals/Untitled design (8).png",
          "./assets/gallery/sandals/Untitled design (11).png",
          "./assets/gallery/sandals/Untitled design (12).png",
          "./assets/gallery/sandals/Untitled design (15).png",
          "./assets/gallery/sandals/Untitled design (16).png",
          "./assets/gallery/sandals/Untitled design (17).png",
          "./assets/gallery/sandals/Untitled design (18).png",
          "./assets/gallery/sandals/Untitled design (19).png",
          "./assets/gallery/sandals/Untitled design (20).png",
          "./assets/gallery/sandals/Untitled design (21).png",
          "./assets/gallery/sandals/Untitled design (22).png",
          "./assets/gallery/sandals/Untitled design (23).png",
          "./assets/gallery/sandals/Untitled design (24).png",
          "./assets/gallery/sandals/Untitled design (25).png",
          "./assets/gallery/sandals/Untitled design (26).png",
          "./assets/gallery/sandals/Untitled design (27).png",
          "./assets/gallery/sandals/Untitled design (28).png",
          "./assets/gallery/sandals/Untitled design.png",
        ]}
      />
      <HeelsSection
        sectionTitle={"Mules"}
        images={[
          "./assets/gallery/mules/Untitled design (12).png",
          "./assets/gallery/mules/Untitled design (13).png",
          "./assets/gallery/mules/Untitled design (10).png",
          "./assets/gallery/mules/Untitled design (11).png",
          "./assets/gallery/mules/Untitled design (1).png",
          "./assets/gallery/mules/Untitled design (2).png",
          "./assets/gallery/mules/Untitled design (3).png",
          "./assets/gallery/mules/Untitled design (4).png",
          "./assets/gallery/mules/Untitled design (5).png",
          "./assets/gallery/mules/Untitled design (6).png",
          "./assets/gallery/mules/Untitled design (7).png",
          "./assets/gallery/mules/Untitled design (8).png",
          "./assets/gallery/mules/Untitled design (9).png",
          "./assets/gallery/mules/Untitled design.png",
          "./assets/gallery/mules/Untitled design (14).png",
        ]}
      />

      <HeelsSection
        sectionTitle={"Flat Shoes"}
        images={[
          "./assets/gallery/flatshoes/Untitled design (4).png",
          "./assets/gallery/flatshoes/Untitled design (5).png",
          "./assets/gallery/flatshoes/Untitled design (6).png",
          "./assets/gallery/flatshoes/Untitled design (7).png",
          "./assets/gallery/flatshoes/Untitled design (1).png",

          "./assets/gallery/flatshoes/Untitled design (8).png",
          "./assets/gallery/flatshoes/Untitled design (9).png",
          "./assets/gallery/flatshoes/Untitled design (10).png",
          "./assets/gallery/flatshoes/Untitled design (11).png",
          "./assets/gallery/flatshoes/Untitled design (12).png",
          "./assets/gallery/flatshoes/Untitled design (13).png",
          "./assets/gallery/flatshoes/Untitled design.png",
        ]}
      />

      <HeelsSection
        sectionTitle={"My Naked Feet"}
        images={[
          "./assets/gallery/nakedfeet/Untitled design (7).png",
          "./assets/gallery/nakedfeet/Untitled design (4).png",
          "./assets/gallery/nakedfeet/Untitled design (2).png",
          "./assets/gallery/nakedfeet/Untitled design (8).png",
          "./assets/gallery/nakedfeet/Untitled design (1).png",
          "./assets/gallery/nakedfeet/Untitled design (3).png",
          "./assets/gallery/nakedfeet/Untitled design (5).png",
          "./assets/gallery/nakedfeet/Untitled design (6).png",
        ]}
      />
      {/* <div className="big">
        <img src="./assets/gallery/13.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/1.jpg"></img>
      </div>
      <div className="big">
        <img src="./assets/gallery/2.png"></img>
      </div>
      <div className="mid">
        <img src="./assets/gallery/3.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/4.jpg"></img>
      </div>
      <div className="big">
        <img src="./assets/gallery/5.jpeg"></img>
      </div>

      <div>
        <img src="./assets/gallery/6.jpeg"></img>
      </div>
      <div>
        <img src="./assets/gallery/7.jpeg"></img>
      </div>
      <div>
        <img src="./assets/gallery/8.jpeg"></img>
      </div>
      <div>
        <img src="./assets/gallery/9.jpeg"></img>
      </div>
      <div className="big">
        <img src="./assets/gallery/10.jpeg"></img>
      </div>

      <div className="big">
        <img src="./assets/gallery/11.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/12.jpg"></img>
      </div>

      <div>
        <img src="./assets/gallery/16.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/17.jpg"></img>
      </div>

      <div>
        <img src="./assets/gallery/18.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/19.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/20.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/21.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/22.jpg"></img>
      </div>

      <div>
        <img src="./assets/gallery/23.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/24.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/25.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/26.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/27.jpg"></img>
      </div>

      <div>
        <img src="./assets/gallery/28.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/30.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/31.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/32.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/33.jpg"></img>
      </div>

      <div>
        <img src="./assets/gallery/34.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/35.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/36.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/39.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/40.jpg"></img>
      </div>

      <div>
        <img src="./assets/gallery/41.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/42.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/43.jpg"></img>
      </div>

      <div>
        <img src="./assets/gallery/45.jpg"></img>
      </div>
      <div>
        <img src="./assets/gallery/46.jpg"></img>
      </div> */}
    </div>
  );
}

export default Gallery;
