import React from "react";
import GallerySectionImage from "../../GallerySectionImage/GallerySectionImage";
import "./HeelsSection.scss";

function GallerySection(props) {
  return (
    <div className="gallery-section">
      <div className="gallery-section-title">
        <p className="section-title-text">{props.sectionTitle}</p>
        <span className="section-title-style"></span>
      </div>
      <div className="gallery-section-wrapper">
        {props.images.map((i) => {
          return (
            <div className="gallery-section-wrapper-item">
              <GallerySectionImage img={i} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GallerySection;
