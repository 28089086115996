import React from "react";
import "./ContactSocialLink.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ContactSocialLink(props) {
  return (
    <div className="social-link-container">
      {props.isLinkBtn && (
        <a className="social-link-content" href={props.link} target="_blank">
          <span
            className="social-icon-wrapper"
            style={{ color: props.iconColor }}
          >
            <FontAwesomeIcon icon={props.icon} />
          </span>
          <p className="social-link-name">{props.name}</p>
        </a>
      )}

      {!props.isLinkBtn && (
        <span className="social-link-content">
          <span
            className="social-icon-wrapper"
            style={{ color: props.iconColor }}
          >
            <FontAwesomeIcon icon={props.icon} />
          </span>
          <p className="social-link-name">{props.name}</p>
        </span>
      )}
    </div>
  );
}

export default ContactSocialLink;
