import React from "react";
import QNA from "./QNA";
import "./QNASection.scss";

const QNASection = () => {
  return (
    <div className="qna-section-container">
      <div className="qna-wrapper">
        <div className="section-title">
          <p className="section-title-text">FAQs</p>
          <span className="section-title-style"></span>
        </div>

        <QNA question={"Where are you from?"} answer={"Thailand"} />
        <QNA question={"What size of my feet?"} answer={"US 6 UK3"} />
        <QNA
          question={"Male or Female?"}
          answer={"Don't like this question:( REAL FEMALE )"}
        />
        <QNA
          question={
            "Can I be your slave, do you like to be worshipped? Do you like your feet to be licked or footjob or anything like this question?"
          }
          answer={"Not really"}
        />
        <QNA
          question={"How can I contact you?"}
          answer={
            "Only DM OR EMAIL: worldsexiestfeet54@gmail.com no whatsapp or Kik or Line or wechat sorry"
          }
        />
        <QNA
          question={"Why took me too long to reply?"}
          answer={
            "I have my main job and some priority things in life to do and this is just a hobby. All Requisitions are up to me except if you are my fav fans with respectful or my good customers so I would kindly response. Please know that I am in Asia and live in Thailand which is very few people who like feet and feet are inappropriate thing to talk about so I won't do thing that discredit my work life or anything that my society can't accept!"
          }
        />
        <QNA
          question={"How old are you?"}
          answer={
            "I don't need to tell and only who I really want to be friends with so I will tell my age."
          }
        />

        <QNA
          question={"Can you send me pics of your feet?"}
          answer={"No as I only do for purchase..I DONT SEND PICS FOR FREE."}
        />
      </div>
    </div>
  );
};

export default QNASection;
