import React from "react";
import "./GallerySectionImage.scss";

function GallerySectionImage(props) {
  return (
    <div className="gallery-img-card">
      <div className="gallery-img-card-rotated"></div>
      <div className="gallery-img-card-rotated"></div>
      <div className="gallery-img-card-rotated"></div>
      <div className="gallery-img-card-rotated">
        <img src={props.img}></img>
      </div>
    </div>
  );
}

export default GallerySectionImage;
