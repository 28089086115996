import {
  faArrowDown,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./QNA.scss";

const QNA = (props) => {
  const [viewQuestion, setViewQuestion] = useState(false);

  const toggleQuestion = () => {
    setViewQuestion(!viewQuestion);
  };

  return (
    <div className="qna-container">
      <div className="question-header" onClick={toggleQuestion}>
        <div className="question-icon">
          <p>Q</p>
        </div>
        <p className="question-text">{props.question}</p>
        <span className="question-view-answer-btn" onClick={toggleQuestion}>
          <FontAwesomeIcon icon={viewQuestion ? faChevronUp : faChevronDown} />
        </span>
      </div>
      <div className={`question-payload ${viewQuestion ? "open" : ""}`}>
        <div className="question-icon">A</div>
        <p className="question-text">{props.answer}</p>
      </div>
    </div>
  );
};

export default QNA;
