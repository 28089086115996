import "./App.scss";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Nav from "./components/nav/Nav";
import MainSection from "./components/mainsection/MainSection";
import QNASection from "./components/qna/QNASection";
import MyStorySection from "./components/mystorysection/MyStorySection";
import SocialMedia from "./components/socialMediaContacts/SocialMedia";
import ShopSection from "./components/shop/ShopSection";
import Footer from "./components/footer/Footer";
import Gallery from "./components/gallery/Gallery";

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <MainSection />
              <MyStorySection />
              <SocialMedia />
              <ShopSection />
              <QNASection />
            </>
          }
        />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route path="/*" element={<></>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
