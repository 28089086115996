import React from "react";
import "./MyStorySection.scss";

const MyStorySection = () => {
  return (
    <div className="qna-section-container my-story-section">
      <div className="qna-wrapper  my-story-section">
        <div className="section-title">
          <p className="section-title-text">My Story</p>
          <span className="section-title-style"></span>
        </div>

        <div className="my-story-wrapper">
          <p className="my-story-para-breaker">
            I am known to many as "Worldsexiestfeet" and I am of Asian
            ethnicity. I have acquired my name when I first was introduced into
            the foot modeling world. In 2014, I posted a picture of my feet and
            received so many positive comments and feedback. One of them was
            from a foot modeling agency and they gave me the start I needed.
            Ever since then my feet have been one of the most recognizable feet
            across social media. With my unique poses and soft wrinkly soles I
            have gained a loyal fanbase over the years. I love wearing shoes,
            I'm more recognized for my designer heels. I've decided on making my
            own page due to having many pages deleted in the past, it has been
            decided that I need a place where I will be available for my fans
            without the fear of being deleted. Having my previous pages taken
            down has made me feel disappointed, but it has not stopped me. I
            want to say thank you to my loyal fans who have inspired me on
            making this big step in my foot modeling journey. Be sure to check
            out my other links that I have posted on my website.
          </p>

          <p className="my-story-para-breaker">
            I have had quite a few followers ask me about the foot modeling on
            Ig, so I have decided to take a moment and share a little about me
            🤗
          </p>

          <p className="my-story-para-breaker">
            Firstly, I have always looked after my feet 🦶🏻At the age of 14 I
            visited the nail spa for the first time, and I've been visiting on a
            regular basis ever since! I just love to have a nice color on my
            toes and keeping them pretty.
          </p>

          <p className="my-story-para-breaker">
            After a few years ago (2014) , one of my friends took a trip to
            Brazil and on her return she brought me back some flip-flops as a
            gift. I loved them and she encouraged me to take a pic and post on
            Instagram. So i did.. and my Instagram notifications went crazy
            within a short space of time ! I received so many positive comments
            for my feet and likes that I was shocked and flattered at the same
            time🥰 I was happy so many people thought my feet were pretty :)
          </p>

          <p className="my-story-para-breaker">
            From that moment I had quite a few foot modelling agencies contacted
            me and in conjunction with comments from followers, I learnt a lot
            of different foot poses and discovered what people liked and wanted
            to see.
          </p>

          <p className="my-story-para-breaker">
            I enjoy sharing pics of my feet and I have continued posting pics
            ever since. I'm flattered by how popular my feet and page have
            become and I would like to thank all my followers for the continued
            love and support. I hope you all keep enjoying my posts! Ciao x
          </p>

          <p className="author-name">Kate</p>
        </div>
      </div>
    </div>
  );
};

export default MyStorySection;
