import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-placeholder"> </div>
      <div className="copyright-info">
        <p className="footer-text">
          &copy; 2021 Katherinelondonfeet. All right reserved.
        </p>
      </div>
      <div className="developer-info">
        <span className="footer-text">
          Developed By{" "}
          <a
            className="developer-contact-link"
            href="https://twitter.com/the_dev_dx"
            target="_blank"
          >
            @DevDx
          </a>
        </span>
      </div>
    </div>
  );
}

export default Footer;
