import {
  faBookmark,
  faComment,
  faCommentDollar,
  faHeart,
  faPaperPlane,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./MainSection.scss";

const MainSection = () => {
  return (
    <div className="main-section-container">
      <div className="main-section-wrapper">
        <img
          className="test"
          src="./assets/img211.png"
          style={{
            shapeOutside: `url("./assets/img211.png")`,
          }}
        />

        <div className="hero-title-container">
          <div className="hero-title">
            <span>World</span>
            <span className="hero-title-span">Sexiest</span>
            <span>Feet</span>
          </div>
        </div>

        <div className="hero-second-title-container">
          <p className="hero-second-title">
            Katherine London’s Feet<span className="hero-title-span"></span>
          </p>
        </div>

        <div className="hero-desription-container">
          <p className="hero-description">
            Worldsexiestfeet. A beauty from Asia, she’s a popular model in the
            foot fetish community who graced the scene back in 2014. She first
            posted photos of her flawless feet on her Instagram page and became
            an instant hit. Although her page has been unjustifiably banned
            multiple times, she continues to create content for her adoring fans
            on a number of platforms including Twitter and Onlyfans. Naturally,
            she has an interest in designer shoes which helps accentuate her
            lovely size 6 feet. Her toes are long and slender, skin as smooth as
            silk, she can sport virtually any color on her pedicures and
            continue to mesmerize.
          </p>
        </div>

        <div className="hero-btn-container">
          <a
            className="hero-btn auto-hide"
            href="https://onlyfans.com/worldsexiestfeet"
            target="_blank"
          >
            <span className="hero-btn-icon">
              <FontAwesomeIcon icon={faCommentDollar} />
            </span>
            <span className="hero-btn-text">OnlyFans</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
